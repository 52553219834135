const doButtons = document.querySelectorAll('button[name="do"][data-spinner]');

doButtons.forEach(function (button) {
    button.addEventListener("click", function (event) {
        event.preventDefault();
        // Change button text to "Logging in..."
        button.innerHTML += '<span class="spinner"></span>';
        // Disable the button to prevent multiple clicks
        button.disabled = true;


        let form = button.closest('form');

        let formData = new FormData(form);
        formData.append('do', button.value);

        let fetchOptions = {
            method: "POST",
            body: formData,
            credentials: "same-origin",
            headers: {"Accept": "text/html"}
        };

        fetch(form.action, fetchOptions)
            .then(response => response.text())
            .then(html => {
                document.open();
                document.write(html);
                document.close();
            })
            .catch(error => {
                console.error('Error:', error);
            });
    });
});